import {
  getModule,
  Module,
  MutationAction,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { DetailResponse } from "@/api/ticket/response";
import * as TicketAPI from "@/api/ticket";
import { isSuccess } from "@/api/response";
import { DetailRequest } from "@/api/ticket/request";

const MODULE_NAME = "ticket/detail";

/**
 * デジタルチケット詳細情報登録更新API（/detail-ticket）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Detail extends VuexModule {
  // state
  detailResponse: DetailResponse = {} as DetailResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.detailResponse);
  }

  get getMessage() {
    return this.detailResponse.message;
  }

  get getDetail() {
    return this.detailResponse.results;
  }

  // MutationActions
  @MutationAction
  async detail(detailRequest: DetailRequest) {
    const detailResponse = await TicketAPI.detail(detailRequest);
    return {
      detailResponse
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      detailResponse: {} as DetailResponse
    };
  }
}

export default getModule(Detail);
