import service from "@/api/service";
import { GetResponse } from "@/api/stamp-info/response";
import { GetRequest } from "@/api/stamp-info/request";

/**
 * スタンプカード情報取得APIをコールします。
 *
 * @return GetResponse
 */
export async function get(getRequest: GetRequest) {
  const response = await service.post("/get-stamp-info", getRequest);
  return response.data as GetResponse;
}
