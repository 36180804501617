import service from "@/api/service";
import { GetResponse } from "@/api/fixed-phrase/response";

/**
 * 注意書き定型文取得APIをコールします。
 *
 * @return GetResponse
 */
export async function get() {
  const response = await service.post("/get-fixed-phrase");
  return response.data as GetResponse;
}
