import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import UlContentHeader from "@/components/UlContentHeader.vue";
import UlBreadcrumbs from "@/components/UlBreadcrumbs.vue";
import UIDataTable from "@/components/UIDataTable.vue";
import UIFormRow from "@/components/UIFormRow.vue";
import UIDatePicker from "@/components/UIDatePicker.vue";
import UlFileRegister from "@/components/UlFileRegister.vue";
import { UlFileRegisiterTabType } from "@/utils/enums";
import RedirectWithAlert from "@/models/mixins/redirect-with-alert";
import WindowSizeChecker from "@/utils/window-size-checker";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import { TableOptions } from "@/api/request";
import { GetRequest } from "@/api/auth-cord-ticket/request";
import { GetRequest as GetStampRequest } from "@/api/stamp-info/request";
import { DetailRequest, RegisterRequest } from "@/api/ticket/request";
import { GetItem } from "@/api/auth-cord-ticket/response";
import TicketDetail from "@/store/ticket/detail";
import TicketRegister from "@/store/ticket/register";
import ShopTicketGet from "@/store/shop-ticket/get";
import AuthCordTicketGet from "@/store/auth-cord-ticket/get";
import FixedPhraseGet from "@/store/fixed-phrase/get";
import StampInfoGet from "@/store/stamp-info/get";
import Flash, { ErrorAlert } from "@/store/common/flash";
import Loading from "@/store/loading";
import WindowOpen from "@/utils/window-open";
import ShopDetail from "@/store/shop/detail";
import { BusinessServiceStorage } from "@/store/business-service-storage";

const enum NextLabelEnum {
  NEXT = "次へ",
  SAVE = "保存する"
}

@Component({
  components: {
    UlContentHeader,
    UlBreadcrumbs,
    UIDataTable,
    UIFormRow,
    UIDatePicker,
    UlFileRegister,
    quillEditor
  }
})
export default class NewEdit extends Mixins(RedirectWithAlert) {
  @Prop({ type: String, required: false })
  id?: string | null; // デジタルチケットID

  // タイトル
  headingMain = "デジタルチケット";
  headingSub = "Digital ticket";

  // テーブルヘッダ（UIDataTableコンポーネントに渡す）
  tableHeaders = [
    { text: "店舗ID", value: "id", sortable: false },
    { text: "店舗名", value: "name", sortable: false },
    // { text: "", value: "space", sortable: false },
    {
      text: "認証コード",
      value: "authCode",
      sortable: false
    },
    {
      label: "編集",
      text: "",
      value: "edit",
      sortable: false
    }
  ];

  menuProps = {
    closeOnClick: false,
    closeOnContentClick: false,
    disableKeys: true,
    openOnClick: false,
    maxHeight: 304
  };

  editorOption = {
    modules: {
      toolbar: [
        [{ color: [] }, { background: [] }],
        ["bold", "italic", "underline", "strike"],
        [{ size: ["small", false, "large", "huge"] }],
        [{ align: [] }],
        ["link", "image"],
        ["clean"]
      ]
    }
  };

  // パンくず
  get breadCrumbs() {
    if (this.isEdit) {
      return [
        { text: "アピール", disabled: true },
        { text: "デジタルチケット", disabled: true },
        {
          text: "店舗一覧",
          disabled: true,
          to: { name: "ticket" }
        },
        {
          text: "デジタルチケット一覧",
          disabled: false,
          to: {
            name: "ticket-list",
            params: { shopId: this.shopId },
            query: { shopName: this.shopName }
          }
        },
        { text: "編集", disabled: true }
      ];
    } else {
      return [
        {
          text: "店舗一覧",
          disabled: false,
          to: { name: "ticket" }
        },
        {
          text: "デジタルチケット一覧",
          disabled: false,
          to: {
            name: "ticket-list",
            params: { shopId: this.shopId },
            query: { shopName: this.shopName }
          }
        },
        { text: "新規作成", disabled: true }
      ];
    }
  }

  initImageUrl = null as string | null;
  allowedTypes = ["image/jpeg", "image/png", "image/gif"];
  tabType = UlFileRegisiterTabType.Format;

  secondPageTabItems = [
    {
      title: "1回券",
      disabled: false
    },
    {
      title: "回数券",
      disabled: false
    },
    {
      title: "定期券",
      disabled: false
    }
  ];

  commutationTicketTabItems = [
    { title: "自動更新定期券", disabled: false },
    { title: "手動更新定期券", disabled: false }
  ];

  // 1ページ目の表示かどうか
  isFirstPage = true;
  isSecondPage = false;

  // 次へ or 保存のラベル
  nextLabel = NextLabelEnum.NEXT;

  // テーブル検索オプション（UIDataTableコンポーネントに渡す）
  tableOptions = {} as TableOptions;

  inputParams = {
    /** デジタルチケットID */
    id: null,
    /** 店舗ID */
    shopId: 0,
    /** 店舗IDリスト */
    selectShopIds: [],
    /** 公開設定 0:非公開、1:公開、2:期間限定公開 */
    publicFlg: 0,
    /** 期間限定公開開始日 */
    publicStartDate: null,
    /** 期間限定公開終了日 */
    publicEndDate: null,
    /** チケットタイトル */
    title: "",
    /** チケット販売価格 */
    price: "",
    /** 割引価格表示 0:なし、1:あり */
    isDiscounted: 0,
    /** 割引キャッチコピー */
    commentForDiscount: null,
    /** 割引価格 */
    discountedPrice: null,
    /** 軽減税率設定 0:しない、1:する */
    reducedTaxRate: 0,
    /** チケット詳細 */
    description: "",
    /** 注意書き */
    notice: null,
    /** 注意書き定型文 */
    textType: [],
    /** デジタルチケットタイプ 1:1回券、2:回数券、3:定期券 */
    type: 1,
    /** チケット有効期限 1:購入日から、2:期限設定、3:有効期限なし */
    expirationType: 3,
    /** チケット有効期限購入日から（数字） */
    expirationRange: null,
    /** チケット有効期限購入日から（単位） 1:日、2:週、3:月 */
    expirationTypeFrom: null,
    /** チケット有効期限期間設定（開始日） */
    startDate: null,
    /** チケット有効期限期間設定（終了日） */
    endDate: null,
    /** チケット販売制限 0:なし、1:先着、2:抽選 */
    limitFlg: 0,
    /** チケット販売制限（人数） */
    limitNumber: null,
    /** 抽選方法 1:自動、2:手動 */
    lotteryType: 1,
    /** チケット利用制限 1:なし、2:あり */
    limitAvailabilityType: 1,
    /** チケット利用制限（数字） */
    limitAvailabilityRange: null,
    /** チケット利用制限（単位） 1:日、2:週、3:月 */
    limitAvailabilityTypeFrom: null,
    /** チケット利用制限（回数） */
    limitAvailabilityNum: null,
    /** チケット利用回数 */
    numberUse: null,
    /** 定期券チケットタイプ 1:自動更新定期券、2:手動更新定期券 */
    subscriptionType: 1,
    /** 更新期限（数字） */
    subscriptionRange: null,
    /** 更新期限（単位） 1:日、2:週、3:月 */
    subscriptionTypeFrom: null,
    /** 手動更新（有効期限） 1:購入日から、2:期限設定 */
    subscriptionManualType: 1,
    /** 手動更新（期限設定開始日） */
    subscriptionManualStartDate: null,
    /** 手動更新（期限設定終了日） */
    subscriptionManualEndDate: null,
    /** 画像編集フラグ */
    backgroundImageEditFlg: 0,
    /** チケットデザイン画像パス */
    backgroundImagePath: null,
    /** t_shop_image_histories.id */
    backgroundImageId: null,
    /** ポイント付与フラグ 0:なし、1:あり */
    pointSyncFlg: 0,
    /** ポイント付与率 */
    pointRate: null,
    /** スタンプ付与フラグ 0:なし、1:あり */
    stampSyncFlg: 0,
    /** スタンプカード番号 */
    stampSeq: null,
    /** スタンプ付与数 */
    stampCount: null,
    /** プレビュー機能使用フラグ */
    isPreview: 0,
    /** チケット非公開後のチケット更新設定  0:あり、1:なし**/
    ticketNonpublicUpdateFlg: 0
  } as RegisterRequest;

  writeFlg = 0;
  limitNumbers = [] as (number | null)[];
  orgPointFlg = 0;
  stampFlg = 0;
  businessId = 0 as number;
  

  /**
   * クエリパラメータから店舗IDを返却する（必須）
   */
  get shopId() {
    if (this.$route.query.shopId) {
      return this.$route.query.shopId;
    } else {
      return "";
    }
  }

  /**
   * クエリパラメータから店舗名を返却する（必須）
   */
  get shopName() {
    if (this.$route.query.shopName) {
      return this.$route.query.shopName;
    } else {
      return "";
    }
  }

  get isEdit() {
    return !!this.id;
  }

  get loading() {
    return Loading.isLoading;
  }

  get shopItems() {
    return ShopTicketGet.getItems;
  }

  get tableItems() {
    return AuthCordTicketGet.getItems;
  }

  get totalCount() {
    return AuthCordTicketGet.getItems.length;
  }

  get fixedPhraseItems() {
    // value が文字列で返却されるため
    return FixedPhraseGet.getItems.map(i => {
      return { id: Number(i.value), name: i.contents };
    });
  }

  get sendTermType() {
    return [
      { id: 1, name: "日" },
      { id: 2, name: "週" },
      { id: 3, name: "ヶ月" }
    ];
  }

  get stampItems() {
    return StampInfoGet.getItems;
  }

  get secondPageActiveTab() {
    switch (this.inputParams.type) {
      case 1:
        return 0;
      case 2:
        return 1;
      case 3:
        return 2;
      default:
        return 0;
    }
  }

  set secondPageActiveTab(val) {
    switch (val) {
      case 0:
        this.inputParams.type = 1;
        break;
      case 1:
        this.inputParams.type = 2;
        break;
      case 2:
        this.inputParams.type = 3;
        break;
    }
  }

  get commutationTicketActiveTab() {
    switch (this.inputParams.subscriptionType) {
      case 1:
        return 0;
      case 2:
        return 1;
      default:
        return 0;
    }
  }

  set commutationTicketActiveTab(val) {
    switch (val) {
      case 0:
        this.inputParams.subscriptionType = 1;
        break;
      case 1:
        this.inputParams.subscriptionType = 2;
    }
  }

  get isTabletAndPC() {
    return WindowSizeChecker.isTabletAndPC(this);
  }

  get isPhoneAndTablet() {
    return WindowSizeChecker.isPhoneAndTablet(this);
  }

  get isEditDisabled() {
    if (!this.isEdit) {
      return false;
    }
    if (TicketDetail.isSuccess && TicketDetail.getDetail.publicFlg == 0 && TicketDetail.getDetail.noneEditFlg == 0) {
      return false;
    }
    return true;
  }

  /**
   * 店舗情報の一覧を取得する処理
   */
  async fetchShopList(): Promise<boolean> {
    if (ShopTicketGet.isSuccess) {
      return true;
    }
    await ShopTicketGet.get();
    if (!ShopTicketGet.isSuccess) {
      await Flash.setErrorNow({
        message: ShopTicketGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    return ShopTicketGet.isSuccess;
  }

  async created() {
    if (!this.shopId) {
      await this.$router.push({ name: "ticket" });
      await Flash.setErrorNow({
        message: "パラメータが不足しています。",
        showReloadButton: false
      } as ErrorAlert);
      return;
    }
    await this.fetchShopList();
    this.inputParams.shopId = Number(this.$route.query.shopId);
    let getErrorMessage = null as string | null;
    const authCordTicket = AuthCordTicketGet.get({
      shopId: Number(this.shopId)
    } as GetRequest);
    const fixedPhrase = FixedPhraseGet.get();
    const stampInfo = StampInfoGet.get({
      shopId: Number(this.shopId)
    } as GetStampRequest);
    await Promise.all([authCordTicket, fixedPhrase, stampInfo]);
    if (!AuthCordTicketGet.isSuccess) {
      getErrorMessage = AuthCordTicketGet.getMessage;
    }
    if (!FixedPhraseGet.isSuccess) {
      getErrorMessage = FixedPhraseGet.getMessage;
    }
    if (!StampInfoGet.isSuccess) {
      getErrorMessage = StampInfoGet.getMessage;
    }
    if (getErrorMessage) {
      await Flash.setErrorNow({
        message: getErrorMessage,
        showReloadButton: true
      });
      return;
    }

    if (this.isEdit) {
      await TicketDetail.detail({
        id: Number(this.id),
        shopId: Number(this.shopId)
      } as DetailRequest);
      // 20201223 C80_デジタルチケット-新規登録・編集画面 -2_No.163~185 presq ishida start
      this.secondPageTabItems.forEach(value => (value.disabled = true));
      // 20201223 C80_デジタルチケット-新規登録・編集画面 -2_No.163~185 presq ishida end
      if (!TicketDetail.isSuccess) {
        await Flash.setErrorNow({
          message: TicketDetail.getMessage,
          showReloadButton: true
        });
        return;
      }
      this.syncInputParams();
    } else {
      this.inputParams.shopId = Number(this.shopId);
      // 新規の場合は、画像は必須
      this.inputParams.backgroundImageEditFlg = 1;
    }

    // ショップ情報取得
    await ShopDetail.detail({ id: this.shopId!! });
    if (!ShopDetail.isSuccess) {
      await Flash.setErrorNow({
        message: ShopDetail.getMessage,
        showReloadButton: true
      });
      return;
    }
    this.orgPointFlg = ShopDetail.getDetail.orgPointFlg;
    this.stampFlg = ShopDetail.getDetail.stampFlg;
    // 20201225 C81	_デジタルチケット-新規登録・編集画面 -3_No.30(質問管理表_No.16) presq ishida start
    if (!this.isEdit && this.stampFlg == 1) {
      this.inputParams.stampSeq = this.stampItems[0].id;
    }
    // 20201225 C81	_デジタルチケット-新規登録・編集画面 -3_No.30(質問管理表_No.16) presq ishida end

    // 認証コード設定からの戻りの場合3ページ目を表示
    if (localStorage.getItem('ticketEditPage') && localStorage.getItem('ticketEditPage') == 3) {
      this.isFirstPage = false;
      this.inputParams = JSON.parse(localStorage.getItem('ticketEditParams'));
      console.log(this.inputParams.type);
    }
    localStorage.removeItem('ticketEditPage');
    localStorage.removeItem('ticketEditParams');
  }

  async beforeDestroy() {
    await TicketDetail.clearResponse();
    await TicketRegister.clearResponse();
    await ShopTicketGet.clearResponse();
    await AuthCordTicketGet.clearResponse();
    await FixedPhraseGet.clearResponse();
    await StampInfoGet.clearResponse();
  }

  async mounted(){
    const storage = BusinessServiceStorage.getLocalStorage();
    this.businessId = storage.id ? storage.id : 0;
  }
  
  
  setFile(data: string, id: number | null) {
    this.inputParams.backgroundImageEditFlg = 1;
    this.inputParams.backgroundImagePath = data;
    this.inputParams.backgroundImageId = id;
  }

  unsetFile() {
    this.inputParams.backgroundImageEditFlg = 1;
    this.inputParams.backgroundImagePath = null;
    this.inputParams.backgroundImageId = null;
  }

  changeDateFromCallback(date: string) {
    this.inputParams.startDate = date;
  }

  changePublicDateFromCallback(date: string) {
    this.inputParams.publicStartDate = date;
  }

  changeDateToCallback(date: string) {
    this.inputParams.endDate = date;
  }

  changePublicDateToCallback(date: string) {
    this.inputParams.publicEndDate = date;
  }

  changeTicketUpdateDateFromCallback(date: string) {
    this.inputParams.subscriptionManualStartDate = date;
  }

  changeTicketUpdateDateToCallback(date: string) {
    this.inputParams.subscriptionManualEndDate = date;
  }

  async tableChangeCallback(tableOptions: TableOptions) {}

  async editClickCallback(item: GetItem) {
    await this.$router.push({
      name: "auth-code-edit",
      params: { id: item.id },
      query: { authType: "3", ticketEditPage: true } // デジタルチケット
    });
  }

  goBack() {
    if (this.isFirstPage) {
      this.$router.back();
    } else if (this.isSecondPage) {
      window.scrollTo(0, 0);
      this.isFirstPage = true;
      this.isSecondPage = false;
    } else {
      window.scrollTo(0, 0);
      this.nextLabel = NextLabelEnum.NEXT;
      this.isSecondPage = true;
    }
  }

  async preview() {
    this.inputParams.isPreview = 1;
    await this.callRegister();
    if (TicketRegister.isSuccess) {
      WindowOpen.preview(TicketRegister.getPreviewUrl);
    }
  }
  
  async goForward() {
    if (this.nextLabel === NextLabelEnum.SAVE) {
      // NEW_DEV-1957 cyber start
      this.inputParams.isPreview = 0;
      // NEW_DEV-1957 cyber end
      await this.callRegister();
      if (TicketRegister.isSuccess) {
        await this.redirectWithSuccessAlert(
          "デジタルチケットを保存しました。",
          `/digital-ticket/${this.inputParams.shopId}/list`,
          { shopName: this.shopName }
        );
      }
    } else {
      window.scrollTo(0, 0);
      if (this.isFirstPage) {
        this.isFirstPage = false;
        this.isSecondPage = true;
      } else {
        this.isSecondPage = false;
        this.nextLabel = NextLabelEnum.SAVE;
      }
    }
  }

  private async callRegister() {
    await TicketRegister.register(this.createRegisterRequest());
    if (!TicketRegister.isSuccess) {
      await Flash.setErrorNow({
        message: TicketRegister.getMessage,
        showReloadButton: false
      });
    }
  }

  private syncInputParams() {
    this.inputParams = {
      ...this.inputParams,
      ...TicketDetail.getDetail
    };
    this.inputParams.selectShopIds = TicketDetail.getDetail.shopIds;
    switch (this.inputParams.limitFlg) {
      case 1:
        this.limitNumbers[1] = this.inputParams.limitNumber;
        break;
      case 2:
        this.limitNumbers[2] = this.inputParams.limitNumber;
        break;
    }
    if (this.inputParams.backgroundImagePath) {
      this.initImageUrl = this.inputParams.backgroundImagePath;
    }
  }

  private createRegisterRequest() {
    switch (this.inputParams.limitFlg) {
      case 1:
      case 2:
        this.inputParams.limitNumber = this.limitNumbers[
          this.inputParams.limitFlg
        ];
        break;
    }
    const request = { ...this.inputParams };
    if (
      request.backgroundImageEditFlg == 0 ||
      request.backgroundImageId != null
    ) {
      // NEW_DEV-1957 cyber start
      if(this.inputParams.isPreview == 1){
        request.backgroundImageEditFlg = 1;
      } else {
        delete request.backgroundImagePath;
      }
      // NEW_DEV-1957 cyber end
    }
    return request;
  }

  @Watch("inputParams", {deep: true, immediate: true})
  private inputParamsChange(newData, oldData) {
    if (newData && !localStorage.getItem('ticketEditPage')) {
      localStorage.setItem('ticketEditParams', JSON.stringify(newData));
    }
  }
}
