import service from "@/api/service";
import { GetResponse } from "@/api/auth-cord-ticket/response";
import { GetRequest } from "@/api/auth-cord-ticket/request";

/**
 * デジタルチケット認証コード一覧検索APIをコールします。
 *
 * @return GetResponse
 */
export async function get(getRequest: GetRequest) {
  const response = await service.post("/get-auth-cord-ticket", getRequest);
  return response.data as GetResponse;
}
